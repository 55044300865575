<template>
  <div>
    <div class="row">
      <b-col class="col-md-2">
        <b-form-group label="Phân loại(*)">
          <b-form-select
              v-model="inputReport.type_of"
              :options="option.type_of"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col class="col-md-2">
        <b-form-group label="Loại đối soát(*)">
          <b-form-select
              v-model="inputReport.wallet_type"
              :options="option.wallet_type.filter(o => o.type == inputReport.type_of)"
              required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col class="col-md-2">
        <b-form-group label="Ngày bắt đầu (*)">
          <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="inputReport.from"
              required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="col-md-2">
        <b-form-group label="Ngày kết thúc (*)">
          <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="inputReport.to"
              required
          ></b-form-input>
        </b-form-group>
      </b-col>

        <b-col class="col-md-2">
          <b-form-group label="Nhà cung cấp">
            <multiselect
                v-model="inputReport.provider"
                :options="option.provider"
                :multiple="true"
                label="text"
                track-by="value"
            ></multiselect>

<!--            <b-form-select-->
<!--                v-model="inputReport.provider"-->
<!--                :options="option.provider"-->
<!--              ></b-form-select>-->

          </b-form-group>
        </b-col>
          <b-col class="col-md-2">
          <b-form-group label="Loại thẻ">
            <multiselect
                v-model="inputReport.card_type"
                :options="option.card_type"
                :multiple="true"
                label="text"
                track-by="value"
            ></multiselect>
          </b-form-group>
          </b-col>

        <b-col class="col-md-2">
          <b-form-group label="Mệnh giá">
            <multiselect
                v-model="inputReport.denomination"
                :options="option.denomination"
                :multiple="true"
                label="text"
                track-by="value"
            ></multiselect>
          </b-form-group>
        </b-col>
</div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>


import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";
import Multiselect from "vue-multiselect";
const CmsRepository = RepositoryFactory.get("cms");
Vue.component("multiselect", Multiselect);

export default {
  props: ["walletType", "options","typeOf", "optionTypeOf"],
  mixins: [Common],
  data() {
    return {
      inputReport: {
        from: this.getYesterday(),
        to: this.getCurrentDay(),
        provider: null,
        card_type: null,
        denomination: null,
        wallet_type: this.walletType,
        type_of: this.typeOf,
      },
      option: {
        wallet_type: this.options.wallet_type,
        type_of: this.optionTypeOf,
        provider: this.options.in_provider,
        denomination: this.options.in_denomination,
        card_type: this.options.in_types
      },
    };
  },
  methods: {
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = '';
        let response = await CmsRepository.listDataFilter(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.error_code) {
          this.notifyAlert("warn", response.data.message);
        } else {
          if (body.data) {
            this.option.provider = body.data.data.providers;
            this.option.card_type = body.data.data.types;
            this.option.denomination = body.data.data.denomination;
          }
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
  created() {
    // this.getList();
  },
  watch: {
    "inputReport.wallet_type"() {
      this.$emit("updateWalletType", this.inputReport);
    },
    "inputReport.type_of"() {
      this.$emit("updateWalletType", this.inputReport);
    },
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>

<template>

  <div class="row">
<!--    alert-custom alert-white alert-shadow-->
<!--    fade-->
<!--    show-->
<!--    gutter-b-->
<!--    justify-content-end-->
<!--    <b-row>-->
      <div class="col-md-2">
        <b-form-group label="Phân loại(*)">
          <b-form-select
              v-model="inputBilling.type_of"
              :options="option.type_of"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-2" v-if="this.typeOf == 3">
        <b-form-group label="Đơn vị đối soát(*)">
          <b-form-select
              v-model="inputBilling.wallet_type"
              :options="option.wallet_type.filter(o => o.type == inputBilling.type_of)"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Ngày bắt đầu(created)">
          <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="inputBilling.start_created"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Ngày kết thúc(created)">
          <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="inputBilling.end_created"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Ngày bắt đầu(update)">
          <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="inputBilling.start_update"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Ngày kết thúc(update)">
          <b-form-input
              :id="`type-date`"
              :type="`date`"
              v-model="inputBilling.end_update"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-md-2">
        <b-form-group label="Đối tác">
          <b-form-select
              v-model="inputBilling.provider"
              :options="
                    inputBilling.type_of == 'BILLING'
                      ? option.provider
                      : option.tu_provider
                  "
          ></b-form-select>
        </b-form-group>
      </div>
<!--    </b-row>-->
<!--    <b-row>-->
      <div class="col-md-2">
        <b-form-group label="Mã thanh toán">
          <b-form-input v-model="inputBilling.tran_code"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Mã đơn hàng">
          <b-form-input v-model="inputBilling.order_code"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Mã giao dịch">
          <b-form-input v-model="inputBilling.napasId"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Mã đối tác">
          <b-form-input v-model="inputBilling.supplier_order"></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-2" v-if="inputBilling.type_of == 'TOPUP_MOBILE'">
        <b-form-group label="Nhà mạng">
          <b-form-select
              v-model="inputBilling.type"
              :options="listType"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Trạng thái order">
          <b-form-select
              v-model="inputBilling.order_status"
              :options="option.order_status"
          ></b-form-select>
        </b-form-group>
      </div>
      <div class="col-md-2">
        <b-form-group label="Trạng thái GD">
          <b-form-select
              v-model="inputBilling.tran_status"
              :options="option.tran_status"
          ></b-form-select>
        </b-form-group>
      </div>
    <div class="col-md-2" v-if="inputBilling.type_of == 'TOPUP_MOBILE'">
      <b-form-group label="Mã gọi NCC">
        <b-form-input
            v-model="inputBilling.code_9pay"
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="col-md-2" v-if="inputBilling.type_of != 'TOPUP_MOBILE'">
      <b-form-group label="Mã hợp đồng" >
        <b-form-input
            v-model="inputBilling.bill_code"
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="col-md-2">
      <b-form-group label="Loại giảm giá" >
        <b-form-select v-model="inputBilling.type_discount">
          <b-form-select-option value="">Tất cả</b-form-select-option>
          <b-form-select-option value="discount">Chiết khấu</b-form-select-option>
          <b-form-select-option value="cashback">Cashback</b-form-select-option>
          <b-form-select-option value="voucher">Voucher</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>
    <div class="col-md-2" v-if="inputBilling.type_of == 'TOPUP_MOBILE'">
      <b-form-group label="Type" >
        <b-form-select
            v-model="inputBilling.action"
            :options="option.action"
        ></b-form-select>
      </b-form-group>
    </div>
<!--    </b-row>-->
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>


import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";
import Multiselect from "vue-multiselect";
const CmsRepository = RepositoryFactory.get("cms");
Vue.component("multiselect", Multiselect);

export default {
  props: ["walletType", "optionWalletType","typeOf", "optionTypeOf"],
  mixins: [Common],
  data() {
    return {
      inputBilling: {
        bill_code: null,
        wallet_type: this.walletType,
        type_of: this.typeOf,
        provider: null,
        order_status: null,
        tran_status: null,
        type: null,
        action: null,
        start_update: this.getYesterday(),
        end_update: this.getCurrentDay(),
        code_9pay: null,
        type_discount: null
      },
      listType: [
        { value: null, text: "ALL" },
      ],
      option: {
        wallet_type: this.optionWalletType,
        type_of: this.optionTypeOf,
        action: [
          { value: null, text: "ALL" },
          { value: "TOPUP_DATA", text: "TOPUP_DATA" },
          { value: "TOPUP_MOBILE", text: "TOPUP_MOBILE" },
        ],
        provider: [
          { value: null, text: "ALL" },
          { value: "PAYOO", text: "PAYOO" },
          { value: "IMEDIA", text: "IMEDIA" },
        ],
        tran_status: [
          { value: null, text: "ALL" },
          { value: "FAIL", text: "FAIL" },
          { value: "FALSE", text: "FALSE" },
          { value: "PENDING", text: "PENDING" },
          { value: "PROCESSING", text: "PROCESSING" },
          { value: "OFFSET", text: "OFFSET" },
          { value: "SUCCESS", text: "SUCCESS" },
          { value: "REFUND", text: "REFUND" },
          { value: "REVERSED", text: "REVERSED" },
          { value: "CANCEL", text: "CANCEL" },
          { value: "CREATE", text: "CREATE" },
          { value: "CREATED", text: "CREATED" },
          { value: "CREATE_REPLACE", text: "CREATE_REPLACE" },
          { value: "CREATE_SUCCESS", text: "CREATE_SUCCESS" },
          { value: "PAYMENT_SUCCESS", text: "PAYMENT_SUCCESS" },
          { value: "WAIT_TO_REFUND", text: "WAIT_TO_REFUND" },
        ],
        order_status: [
          { value: null, text: "ALL" },
          { value: "FAIL", text: "FAIL" },
          { value: "PENDING", text: "PENDING" },
          { value: "SUCCESS", text: "SUCCESS" },
          { value: "REFUND", text: "REFUND" },
          { value: "CREATED", text: "CREATED" },
          { value: "PROCESSING", text: "PROCESSING" },
          { value: "CANCEL", text: "CANCEL" },
          { value: "REJECT", text: "REJECT" },
          { value: "EXPIRED_SESSION", text: "EXPIRED_SESSION" },
          { value: "PAYMENT_PENDING", text: "PAYMENT_PENDING" },
          { value: "PAYMENT_CANCEL", text: "PAYMENT_CANCEL" },
          { value: "PAYMENT_FAIL", text: "PAYMENT_FAIL" },
          { value: "PAYMENT_SUCCESS", text: "PAYMENT_SUCCESS" },
          { value: "WAIT_TO_REVIEW", text: "WAIT_TO_REVIEW" },
        ],
        tu_provider: [
      { value: null, text: "ALL" },
      { value: "Epay", text: "Epay" },
      { value: "Imedia", text: "Imedia" },
      { value: "COMBO_MOBI", text: "Phương Quân" },
    ],
      },
    };
  },
  methods: {
    async getServiceByType() {
      let params = {
        type: 'TOPUP_MOBILE'
      };
      CmsRepository.listServiceByType(params)
          .then((response) => {
            if (response.data.error_code) {
              alert(response.data.message);
            } else {
              this.listType.push(...response.data.data);
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },
  },
  created() {
    this.getServiceByType();
  },
  watch: {
    "inputBilling.wallet_type"() {
      this.$emit("updateWalletType", this.inputBilling);
    },
    "inputBilling.type_of"() {
      this.$emit("updateWalletType", this.inputBilling);
    },
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-danger {
  margin-left: 5px;
}
</style>

<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Đối soát dịch vụ</h6>
        </template>
        <div v-if="formInput.type_of == 3">
          <div
            v-if=" formInput.wallet_type == 'IN_CARD'  || formInput.wallet_type == null"
        >
          <b-row>
            <b-col>
              <b-form-group label="Phân loại(*)">
                <b-form-select
                    v-model="formInput.type_of"
                    :options="options.type_of"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="formInput.type_of == 3">
              <b-form-group label="Loại đối soát(*)">
                <b-form-select
                  v-model="formInput.wallet_type"
                  :options="options.wallet_type.filter(o => o.type == formInput.type_of)"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Nhà cung cấp">
                <multiselect
                    v-model="formInput.provider"
                    :options="options.in_provider"
                    :multiple="true"
                    label="text"
                    track-by="value"
                ></multiselect>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Tìm kiếm theo">
                <b-form-select
                    v-model="formInput.date_type"
                    :options="options.date_type"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Ngày bắt đầu(*)">
                <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="formInput.from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Ngày kết thúc(*)">
                <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="formInput.to"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Sản phẩm">
                <multiselect
                    v-model="formInput.card_type"
                    :options="options.in_types"
                    :multiple="true"
                    label="text"
                    track-by="value"
                ></multiselect>
                              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mã order nhập">
                <b-form-input
                  v-model="formInput.order_number"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Serial">
                <b-form-input
                  v-model="formInput.card_serial"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mệnh giá">
                <b-form-input
                    v-model="formInput.denomination"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Trạng thái">
                <b-form-select
                    v-model="formInput.status"
                    :options="options.status"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Hạn sử dụng">
                <date-picker lang="vi" format="DD/MM/YYYY" v-model="formInput.expired_date" type="date" range placeholder="Chọn khoảng ngày"></date-picker>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

          <div
              v-if="formInput.wallet_type == 'OUT_CARD'"
          >
            <b-row>
              <b-col>
                <b-form-group label="Phân loại(*)">
                  <b-form-select
                      v-model="formInput.type_of"
                      :options="options.type_of"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="formInput.type_of == 3">
                <b-form-group label="Loại đối soát(*)">
                  <b-form-select
                      v-model="formInput.wallet_type"
                      :options="options.wallet_type.filter(o => o.type == formInput.type_of)"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Nhà cung cấp">
                  <multiselect
                      v-model="formInput.provider"
                      :options="options.in_provider"
                      :multiple="true"
                      label="text"
                      track-by="value"
                  ></multiselect>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Sản phẩm">
                  <multiselect
                      v-model="formInput.card_type"
                      :options="options.in_types"
                      :multiple="true"
                      label="text"
                      track-by="value"
                  ></multiselect>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Ngày bắt đầu(*)">
                  <b-form-input
                      :id="`type-date`"
                      :type="`date`"
                      v-model="formInput.from"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Ngày kết thúc(*)">
                  <b-form-input
                      :id="`type-date`"
                      :type="`date`"
                      v-model="formInput.to"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>

              <b-col>
                <b-form-group label="Đơn vị sử dụng">
                  <b-form-select
                      v-model="formInput.source"
                      :options="options.source"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Mã giao dịch">
                  <b-form-input
                      v-model="formInput.wallet_transaction_id"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Mã đơn hàng">
                  <b-form-input
                      v-model="formInput.code"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Serial">
                  <b-form-input
                      v-model="formInput.card_serial"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div>

          <div  v-if="formInput.wallet_type == 'REPORT_CARD'">
            <FormReport
              ref="formReport"
              :walletType="formInput.wallet_type"
              :options="options"
              :typeOf="formInput.type_of"
              :optionTypeOf="options.type_of"
              @updateWalletType="updateWalletType"
          ></FormReport>
          </div>

        </div>


        <div  v-if="formInput.type_of == 'BILLING' || formInput.type_of == 'TOPUP_MOBILE'">
          <FormBilling
              ref="formBilling"
              :walletType="formInput.wallet_type"
              :optionWalletType="options.wallet_type"
              :typeOf="formInput.type_of"
              :optionTypeOf="options.type_of"
              @updateWalletType="updateWalletType"
          ></FormBilling>
        </div>
        <b-row>
          <b-col class="text-right">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="searchData(1)"
              >Tìm kiếm</b-button
            >
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="$bvModal.show('export-desc')"
              >Xuất excel</b-button
            >&nbsp;
          </b-col>
        </b-row>
        <b-row v-if="formInput.wallet_type != 'REPORT_CARD' && formInput.wallet_type != 'OUT_CARD'">
          <b-col></b-col>
          <b-col></b-col>
          <b-col v-if="formInput.wallet_type == 'TOPUP_MOBILE' && this.totalAmount == null">
            <b @click="getTotalTransaction()"><i class="menu-icon flaticon-eye" > </i>Xem tổng tiền</b>
          </b-col>
          <b-col v-if="this.totalAmount != null "><b>Tổng tiền: </b> {{ numberFormat(parseInt(this.totalAmount)) }} VNĐ</b-col>
        </b-row >
        <b-row v-if="formInput.wallet_type != 'REPORT_CARD'">
          <b-col></b-col>
          <b-col></b-col>
          <b-col v-if="formInput.wallet_type == 'OUT_CARD' || formInput.wallet_type == 'IN_CARD'">
            <p><b>Tổng số thẻ: </b> {{ numberFormat(this.total) }} thẻ</p>
            <p><b>Tổng số tiền mệnh giá: </b> {{ this.totalAmount > 0 ? numberFormat(parseInt(this.totalAmount)) : 0 }} VNĐ</p>
          </b-col>
          <b-col v-else><b>Tổng số GD: </b> {{ numberFormat(this.total) }} giao dịch</b-col>
        </b-row>
        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="Danh sách" active>
              <b-table
                :items="items"
                :fields="fields"
                ref="table"
                striped
                hover
                responsive
                caption-top>
                <template #cell(index)="data">
                  {{(view.currentPage - 1) * 25 + (data.index + 1)}}
                </template>
                <template #cell(price)="data">
                  {{ numberFormat(data.item.price) }}
                </template>
                <template #cell(voucher)="data">
                  {{numberFormat(data.item.voucher)}}
                </template>
                <template #cell(cashback)="data">
                  {{numberFormat(data.item.cashback)}}
                </template>
                <template #cell(amount)="data">
                  {{numberFormat(data.item.amount)}}
                </template>
                <template #cell(total)="data">
                  {{numberFormat(data.item.total)}}
                </template>
                <template #cell(order_discount)="data">
                  {{numberFormat(data.item.order_discount)}}
                </template>
                <template #cell(order_total)="data">
                  {{numberFormat(data.item.order_total)}}
                </template>
                <template #cell(denomination)="data">
                  {{numberFormat(data.item.denomination)}}
                </template>
                <template #cell(total_amount)="data">
                  {{numberFormat(data.item.total_amount)}}
                </template>
                <template #cell(total_used_amount)="data">
                  {{numberFormat(data.item.total_used_amount)}}
                </template>
                <template #cell(total_not_used_amount)="data">
                  {{numberFormat(data.item.total_not_used_amount)}}
                </template>
                <template #cell(fee_receiver)="data">
                  {{numberFormat(data.item.fee_receiver)}}
                </template>
                <template #cell(fee_expense)="data">
                  {{numberFormat(data.item.fee_expense)}}
                </template>
                <template #cell(report)="data">
                  {{data.item.total + data.item.total_not_used - data.item.total_used}}
                </template>
                <template #cell(report_amount)="data">
                  {{numberFormat(data.item.total_amount + parseInt(data.item.total_not_used_amount) - data.item.total_used_amount)}}
                </template>
                <template #cell(status)="data">
                  <span>
                    {{ getStatus(data.item) }}
                  </span>
                </template>
                <template #cell(created_at)="data">
                <span>
                  {{ formatDate(data.item.created_at) }}
                </span>
                </template>
              </b-table>

              <div class="mt-3" v-if="formInput.wallet_type != 'REPORT_CARD'">
                <b-pagination-nav
                  v-model="view.currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="view.paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </b-card-group>
    <b-modal id="export-desc" title="Yêu cầu xuất dữ liệu">
      <br />
      <div class="d-block text-center">
        <b-form-textarea
          placeholder="Nhập mô tả yêu cầu xuất dữ liệu ..."
          v-model="formInput.exportDesc"
          rows="3"
        ></b-form-textarea>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()"> Hủy </b-button>
        <b-button size="sm" variant="success" @click="exportData()">
          Xuất
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import FormReport from "@/view/pages/tools/card/FormReport";
import FormBilling from "./FormBilling";
import moment from "moment";
DatePicker.locale('vi')

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  components: {FormReport, FormBilling, DatePicker},
  mixins: [Common],
  data() {
    return {
      items: [],
      total: 0,
      totalAmount: null,
      itemsFile: [],
      fields: [],
      formInput: {
        wallet_transaction_id:null,
        code: null,
        wallet_type: 'IN_CARD',
        status: null,
        from: this.getYesterday(),
        to: this.getCurrentDay(),
        card_type: null,
        denomination: null,
        card_serial: null,
        exportDesc: null,
        provider: null,
        date_type: 'created_at',
        order_number: null,
        source: null,
        action: null,
        type_of : 'BILLING',
        start_update:this.getYesterday(),
        end_update: this.getCurrentDay(),
        expired_date: [],
      },
      options: {
        in_provider: [],
        in_types:[],
        out_provider: [],
        in_denomination:[],
        date_type: [
          { value: "created_at", text: "Thời gian nhập" },
          { value: "date_usage", text: "Thời gian bán" },
        ],
        type_of: [
          { value: 'BILLING', text: "Thanh toán hóa đơn"},
          { value: 'TOPUP_MOBILE', text: "Nạp tiền/data điện thoại"},
          { value: 3, text: "Kho thẻ"},
        ],
        wallet_type: [
          { value: "BILLING", text: "Payoo", type: 1},
          { value: "TOPUP_MOBILE", text: "Nạp tiền/data điện thoại", type: 2},
          { value: "IN_CARD", text: "Nhập thẻ" ,type: 3},
          { value: "OUT_CARD", text: "Xuất thẻ" , type: 3},
          { value: "REPORT_CARD", text: "Nhập - Xuất - Tồn", type: 3},
        ],
        status: [
          {value: null, text: "ALL"},
          {value: '2', text: "AVAILABLE"},
          {value: '0', text: "EXPIRED"},
          {value: '1', text: "SOLD"},
          {value: '-1', text: "LOCK"},
        ],
        source: [
          {value: null, text: "ALL"},
          {value: 'DEFAULT', text: "User Ví"},
          {value: '9SHOP', text: "9SHOP"},
          {value: 'MYVIETTEL', text: "MYVIETTEL"},
          {value: 'MBF', text: "MBF"},
          {value: '9S', text: "9S"},
          {value: 'RECHARGE', text: "RECHARGE"},
        ],
        service: [
          {value: null, text: "ALL"},
          {value: 'PHONE_CARD', text: "Thẻ điện thoại"},
          {value: 'GAME_CARD', text: "Thẻ game"},
          {value: 'MOBILE_NETWORK', text: "Thẻ 3G/4G"},
          {value: 'SERVICE_CARD', text: "Thẻ dịch vụ"},
        ],
      },
      view: {
        currentPage: 1,
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
      download: {
        currentPage: 1,
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
      listType: [
        { value: null, text: "ALL" },
      ],
    };
  },
  mounted() {
    this.getServiceByType();
    this.getList();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đối soát dịch vụ", route: "card" },
    ]);
  },
  methods: {
    getTotalTransaction(){

      if (this.formInput.type_of != 3)
      {
        this.formInput.wallet_type = this.formInput.type_of;
      }

      let found = this.options.wallet_type.find(element => element.value == this.formInput.wallet_type);
      if (this.formInput.wallet_type == 3 && (found.type == this.formInput.type_of)) {
        this.notifyAlert("warn", "Đơn vị đối soát không được bỏ trống");
        return false;
      }

      let params = this.convert({ ...this.formInput });
      if (this.formInput.wallet_type === "REPORT_CARD") {
        let list = { ...this.$refs.formReport.inputReport };
        params = this.removeValidateNull(list);
      } else if (this.formInput.wallet_type === "BILLING" || this.formInput.wallet_type === "TOPUP_MOBILE") {
        let list = { ...this.$refs.formBilling.inputBilling };
        params = this.removeValidateNull(list);
        params.wallet_type = params.type_of
      }

      if ((params.from ==  null || params.to ==  null) && this.formInput.wallet_type === "REPORT_CARD") {
        this.notifyAlert("warn", "Vui lòng nhập khoảng thời gian");
        return false;
      }
      if (params.from > params.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      params.page = this.view.currentPage ?? 1;
      params.date_from = params.from
      params.date_to = params.to
      this.$bus.$emit("show-loading", true);

      if (this.formInput.provider != null && this.formInput.provider.length !=0) {
        params.provider = JSON.stringify(this.listValue(this.formInput.provider))
      }

      if (this.formInput.card_type != null && this.formInput.card_type.length !=0) {
        params.card_type = JSON.stringify(this.listValue(this.formInput.card_type))
      }
      if (this.checkEmptyObject(this.formInput.provider) || this.checkEmptyObject(this.formInput.card_type)  || this.checkEmptyObject(this.formInput.card_serial)
      ) {
        this.formInput.code = null
        this.formInput.wallet_transaction_id = null
        this.formInput.source = null
        this.$forceUpdate();
      }

      CmsRepository.totalReconcileWallet(params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
            } else {
              this.totalAmount = response.data.data.data.total;
              this.notifyAlert("success", "Lấy dữ liệu thành công");
            }
            this.$bus.$emit("show-loading", false);
          })
          .catch(() => {
            this.notifyAlert("warn", "Có lỗi xảy ra");
          });
    },
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = '';
        let response = await CmsRepository.listDataFilter(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.error_code) {
          this.notifyAlert("warn", response.data.message);
        } else {
          if (body.data) {
            this.options.in_provider = body.data.data.providers;
            this.options.in_types = body.data.data.types;
            this.options.in_denomination = body.data.data.denomination;
          }
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    searchData(numberPage = null) {
      if (this.$route.query.page !== "undefined" && numberPage == null) {
        numberPage = this.$route.query.page;
      } else {
        this.$router.replace({});
        this.view.currentPage = 1;
      }

      if (this.formInput.type_of != 3)
      {
        this.formInput.wallet_type = this.formInput.type_of;
      }

      let found = this.options.wallet_type.find(element => element.value == this.formInput.wallet_type);
      if (this.formInput.wallet_type == 3 && (found.type == this.formInput.type_of)) {
        this.notifyAlert("warn", "Đơn vị đối soát không được bỏ trống");
        return false;
      }

      let params = this.convert({ ...this.formInput });
      if (this.formInput.wallet_type === "REPORT_CARD") {
        let list = { ...this.$refs.formReport.inputReport };
        params = this.removeValidateNull(list);
      } else if (this.formInput.wallet_type === "BILLING" || this.formInput.wallet_type === "TOPUP_MOBILE") {
        let list = { ...this.$refs.formBilling.inputBilling };
        params = this.removeValidateNull(list);
        params.wallet_type = params.type_of
      }

      if ((params.from ==  null || params.to ==  null) && this.formInput.wallet_type === "REPORT_CARD") {
        this.notifyAlert("warn", "Vui lòng nhập khoảng thời gian");
        return false;
      }
      if (params.from > params.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      params.page = this.view.currentPage ?? 1;
      params.date_from = params.from
      params.date_to = params.to
      this.$bus.$emit("show-loading", true);

      if (this.formInput.provider != null && this.formInput.provider.length !=0) {
        params.provider = JSON.stringify(this.listValue(this.formInput.provider))
      }

      if (this.formInput.card_type != null && this.formInput.card_type.length !=0) {
        params.card_type = JSON.stringify(this.listValue(this.formInput.card_type))
      }
      if (this.formInput.expired_date[0] && this.formInput.expired_date.length > 0) {
        params.expired_date = moment(this.formInput.expired_date[0]).format("DD-MM-YYYY") + ' - ' + moment(this.formInput.expired_date[1]).format("DD-MM-YYYY");
      } else {
        params.expired_date = null
      }
      if (this.checkEmptyObject(this.formInput.provider) || this.checkEmptyObject(this.formInput.card_type)  || this.checkEmptyObject(this.formInput.card_serial)
           ) {
        this.formInput.code = null
        this.formInput.wallet_transaction_id = null
        this.formInput.source = null
        this.$forceUpdate();
      }
      this.totalAmount = null;


      CmsRepository.listShowReconcileWallet(params)
        .then((response) => {
          this.items = [];
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.items = response.data.data.data;
            this.fields = response.data.data.fields;
            this.totalAmount = response.data.data.totalAmount ?? response.data.data.total_amount ?? null;
            this.total = response.data.data.total;
            this.view.paginate.total = response.data.data.total;
            this.view.paginate.totalPage = response.data.data.lastPage ?? (response.data.data.last_page ?? 1);
            this.view.paginate.currentPage = response.data.data.currentPage ?? (response.data.data.current_page ?? 1) ;
            this.notifyAlert("success", "Lấy dữ liệu thành công");
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xảy ra");
        });
    },
    listValue($arr) {
      let newArr = [];
      $arr.forEach( (item) => {
        newArr.push(item.value)
      })
      return newArr;
    },
    checkEmptyObject(value) {
      if (value == null) {
        return false;
      }

      if (Object.keys(value).length == 0) {
        return false;
      }

      return true;
    },
    exportData() {
      if (this.formInput.type_of != 3)
      {
        this.formInput.wallet_type = this.formInput.type_of;
      }
      let found = this.options.wallet_type.find(element => element.value == this.formInput.wallet_type);
      if (this.formInput.wallet_type == 3 && (found.type == this.formInput.type_of)) {
        this.notifyAlert("warn", "Đơn vị đối soát không được bỏ trống");
        return false;
      }
      if (this.formInput.start_update > this.formInput.end_update) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }
      if (this.formInput.from > this.formInput.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }

      let params = this.convert({ ...this.formInput });
      if (this.formInput.wallet_type === "REPORT_CARD") {
        let list = { ...this.$refs.formReport.inputReport };
        params = this.removeValidateNull(list);
      } else if (this.formInput.wallet_type === "BILLING" || this.formInput.wallet_type === "TOPUP_MOBILE") {
        let list = { ...this.$refs.formBilling.inputBilling };
        params = this.removeValidateNull(list);
        params.wallet_type = params.type_of
      }

      if ((params.from ==  null || params.to ==  null) && this.formInput.wallet_type === "REPORT_CARD") {
        this.notifyAlert("warn", "Vui lòng nhập khoảng thời gian");
        return false;
      }
      if (params.from > params.to) {
        this.notifyAlert("warn", "Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
        return false;
      }

      if (this.formInput.exportDesc != null && this.formInput.exportDesc != "") {
        params.export_desc = this.formInput.exportDesc;
      }

      params.page = this.view.currentPage ?? 1;
      this.$bvModal.hide("export-desc");
      this.$bus.$emit("show-loading", true);
      if (this.formInput.provider != null && this.formInput.provider.length !=0) {
        params.provider = JSON.stringify(this.listValue(this.formInput.provider))
      }

      if (this.formInput.card_type != null && this.formInput.card_type.length !=0) {
        params.card_type = JSON.stringify(this.listValue(this.formInput.card_type))
      }

      if (this.checkEmptyObject(this.formInput.provider) || this.checkEmptyObject(this.formInput.card_type)  || this.checkEmptyObject(this.formInput.card_serial)
      ) {
        this.formInput.code = null
        this.formInput.wallet_transaction_id = null
        this.formInput.source = null
        this.$forceUpdate();
      }
      CmsRepository.exportFileReconcileWallet(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert(
              "success",
              "Tạo tiến trình thành công. Vui lòng chờ"
            );
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.notifyAlert("warn", "Có lỗi xảy ra");
        });
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
    getServiceByType() {
      let params = {
        type: 'TOPUP_MOBILE'
      };
      CmsRepository.listServiceByType(params)
          .then((response) => {
            if (response.data.error_code) {
              alert(response.data.message);
            } else {
              this.listType.push(...response.data.data);
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },
    updateWalletType(e) {
      this.formInput.wallet_type = e.wallet_type;
      if ( e.type_of != 3) {
        this.formInput.wallet_type = e.type_of;
      }
      this.formInput.type_of = e.type_of;
    },
    getStatus(item) {
      if (item.status === "STOCKED" && moment().isBefore(moment(item.expired_date))) {
        return "AVAILABLE";
      } else if (item.status === "STOCKED" && moment().isAfter(moment(item.expired_date))) {
        return "EXPIRED";
      } else if (item.status === "SOLD") {
        return "SOLD"
      } else {
        return "LOCK";
      }
    },
  },
  created() {
    // this.listExportFile();
    // this.searchData();
      },
  watch: {
    "view.currentPage"() {
      this.searchData();
    },
  },
};
</script>
